export const THEME_COLORS = {
  brandBlue: '#3f4694',
  brandLightBlue: '#9edae2',
  brandRed: {
    400: '#f75263',
    700: '#dc2a42',
  },
  white: '#ffffff',
  blueGrey: {
    900: '#263238',
    600: '#546e7a',
  },
  blue: {
    500: '#2196F3',
    600: '#4285f4',
  },
  green: {
    500: '#4CAF50',
    600: '#34a853',
  },
  orange: {
    400: '#FFA726',
    700: '#fbbc05',
  },
  red: '#eb4335',
  gray: '#f4f6f8',
  lightGray: '#f5f5f5',
};
