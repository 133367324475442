import { camelCase } from 'change-case';
import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { EFlowStepEventIgnitors, EFormFieldTypes, Flow, FlowStep } from 'models/flow';
import { User } from 'models/user';
import type { FC, ReactNode } from 'react';
import React, { createContext, useState } from 'react';
import { IFieldData } from 'views/companySetup/CreateFlowView/SelectedStepSettingsCard/AddFieldToFormDialog';

import { IFieldInput } from '../models/flow';

const DYNAMIC_FORM_FIELD_TYPE_SETTINGS_LIST = new Map<EFormFieldTypes, IFieldInput>([
  [
    EFormFieldTypes.SHORT_TEXT,
    {
      jsonSchema: {
        type: 'string',
        maxLength: 90,
      },
    },
  ],
  [
    EFormFieldTypes.LONG_TEXT,
    {
      jsonSchema: {
        type: 'string',
        maxLength: 255,
      },
      uiSchema: {
        'ui:widget': 'textarea',
      },
    },
  ],
  [
    EFormFieldTypes.MULTIPLE_FILES,
    {
      jsonSchema: {
        type: 'array',
        items: {
          type: 'string',
          format: 'data-url',
        },
      },
      uiSchema: {
        filesAccept: {
          'ui:options': {
            accept: '.pdf',
          },
        },
        'ui:widget': 'FileWidget',
      },
    },
  ],
]);

export interface CreateFlowContextValue {
  flow: Flow;
  selectedStepIndex: number;
  saveFlow: (update: Partial<Flow>) => void;
  deleteStepByIndex: (id: number) => void;
  addNewStep: () => void;
  selectStepByIndex: (index: number) => void;
  addFormFieldToFlowStep: (stepIndex: number, fieldData: IFieldData) => void;
  assignCollaboratorToFlowStep: (stepIndex: number, collaborator: User) => void;
  removeCollaboratorById: (collaboratorId: string) => void;
  deleteStepFormField: (fieldLabel: string) => void;
}

interface CreateFlowProviderProps {
  flow?: Flow;
  selectedStepIndex?: number;
  children?: ReactNode;
}

const DEFAULT_STEP_JSON_SCHEMA_FORM: JSONSchema7 = {
  type: 'object',
  required: [],
  properties: {},
};

const DEFAULT_FLOW_STEP_SETTINGS: Partial<FlowStep> = {
  description: null,
  eventIgnitor: EFlowStepEventIgnitors.MANUAL_INTERACTION_EVENT,
  input: {
    jsonSchema: DEFAULT_STEP_JSON_SCHEMA_FORM,
    uiSchema: {},
  },
  notifications: {
    collaborators: [],
  },
};

const DEFAULT_FLOW_SETTINGS: Flow = {
  title: '',
  description: null,
  steps: [],
};

const CreateFlowContext = createContext<CreateFlowContextValue>({
  flow: DEFAULT_FLOW_SETTINGS,
  selectedStepIndex: null,
  saveFlow: () => {},
  deleteStepByIndex: () => {},
  addNewStep: () => {},
  selectStepByIndex: () => {},
  addFormFieldToFlowStep: () => {},
  assignCollaboratorToFlowStep: () => {},
  removeCollaboratorById: () => {},
  deleteStepFormField: () => {},
});

export const CreateFlowProvider: FC<CreateFlowProviderProps> = ({ flow, children }) => {
  const [currentFlow, setCurrentFlow] = useState<Flow>(flow || DEFAULT_FLOW_SETTINGS);
  const [incrementalId, setIncrementalId] = useState<number>(1);
  const [currentSelectedStepIndex, setCurrentSelectedStepIndex] = useState<number>(null);

  const handleSave = (update: Partial<Flow>): void => {
    const mergedSettings = _.merge({}, currentFlow, update);
    setCurrentFlow(mergedSettings);
  };

  const deleteStepByIndex = (index: number) => {
    if (currentSelectedStepIndex === index) {
      setCurrentSelectedStepIndex(null);
    }
    const auxSteps = [...currentFlow.steps];
    auxSteps.splice(index, 1);
    setCurrentFlow({
      ...currentFlow,
      steps: auxSteps,
    });
    resetIncrementalCounter();
  };

  const handleDeleteStepFormField = (fieldLabel: string) => {
    const fieldIdentifier = camelCase(fieldLabel);
    const auxSteps = [...currentFlow.steps];
    delete auxSteps[currentSelectedStepIndex].input.jsonSchema.properties[fieldIdentifier];
    delete auxSteps[currentSelectedStepIndex].input.uiSchema[fieldIdentifier];
    auxSteps[currentSelectedStepIndex].input.jsonSchema.required = auxSteps[
      currentSelectedStepIndex
    ].input.jsonSchema.required.filter((fieldId) => fieldId !== fieldIdentifier);

    setCurrentFlow({
      ...currentFlow,
      steps: auxSteps,
    });
  };

  const handleAddNewStep = () => {
    const newFlow = _.merge({}, currentFlow, {
      ...currentFlow,
      steps: [
        ...currentFlow.steps,
        {
          ...DEFAULT_FLOW_STEP_SETTINGS,
          title: 'Step',
          id: incrementalId,
        },
      ],
    });

    setCurrentFlow(newFlow);
    setIncrementalId(incrementalId + 1);
  };

  const handleSelectStepByIndex = (index: number) => {
    if (currentSelectedStepIndex === index) {
      setCurrentSelectedStepIndex(null);
      return;
    }
    setCurrentSelectedStepIndex(index);
  };

  function resetIncrementalCounter() {
    if (currentFlow.steps.length === 0) {
      setIncrementalId(1);
    }
  }

  function handleAddFormFieldToFlowStep(stepIndex: number, field: IFieldData) {
    const auxSteps = [...currentFlow.steps];

    const flowStep = auxSteps[stepIndex];
    const fieldIdentifier = camelCase(field.label);

    if (field.isRequired) {
      flowStep.input.jsonSchema.required.push(fieldIdentifier);
    }

    if (flowStep.input.jsonSchema.properties[fieldIdentifier]) {
      // TODO: Add a better error message, with snackbar
      alert('This Field already exists!');
    }

    const defaultFieldSettings = DYNAMIC_FORM_FIELD_TYPE_SETTINGS_LIST.get(field.type);

    flowStep.input.jsonSchema.properties = {
      ...flowStep.input.jsonSchema.properties,
      [fieldIdentifier]: {
        title: field.label,
        description: field.description,
        ...defaultFieldSettings.jsonSchema,
      },
    };

    if (defaultFieldSettings.uiSchema) {
      flowStep.input.uiSchema = {
        ...flowStep.input.uiSchema,
        [fieldIdentifier]: {
          ...defaultFieldSettings.uiSchema,
        },
      };
    }

    setCurrentFlow({
      ...currentFlow,
      steps: auxSteps,
    });
  }

  function handleAssignCollaboratorToFlowStep(stepIndex: number, collaborator: User) {
    const updatedSteps = currentFlow.steps;
    const flowStep = updatedSteps[stepIndex];
    let assignedCollaborators = flowStep.notifications.collaborators;
    if (
      assignedCollaborators.find(
        (assignedCollaborator) => assignedCollaborator.id === collaborator.id,
      )
    ) {
      assignedCollaborators = assignedCollaborators.filter(
        (assignedCollaborator) => assignedCollaborator.id !== collaborator.id,
      );

      flowStep.notifications.collaborators = assignedCollaborators;
    } else {
      assignedCollaborators.push(collaborator);
      flowStep.notifications.collaborators = assignedCollaborators;
    }

    updatedSteps[stepIndex] = flowStep;

    setCurrentFlow({
      ...currentFlow,
      steps: updatedSteps,
    });
  }

  function handleRemoveCollaboratorById(collaboratorId: string): void {
    const updatedFlowSteps = currentFlow.steps;
    const updatedFlowStep = updatedFlowSteps[currentSelectedStepIndex];

    updatedFlowSteps[currentSelectedStepIndex].notifications.collaborators =
      updatedFlowStep.notifications.collaborators.filter(
        (assignedCollaborator) => assignedCollaborator.id !== collaboratorId,
      );

    handleSave({
      steps: updatedFlowSteps,
    });
  }

  return (
    <CreateFlowContext.Provider
      value={{
        flow: currentFlow,
        saveFlow: handleSave,
        deleteStepByIndex,
        addNewStep: handleAddNewStep,
        selectedStepIndex: currentSelectedStepIndex,
        selectStepByIndex: handleSelectStepByIndex,
        addFormFieldToFlowStep: handleAddFormFieldToFlowStep,
        assignCollaboratorToFlowStep: handleAssignCollaboratorToFlowStep,
        removeCollaboratorById: handleRemoveCollaboratorById,
        deleteStepFormField: handleDeleteStepFormField,
      }}
    >
      {children}
    </CreateFlowContext.Provider>
  );
};

export const CreateFlowContextConsumer = CreateFlowContext.Consumer;

export default CreateFlowContext;
