import Axios from 'axios';
import { BASE_API_URL } from 'constants/api';
import { Alert, enqueueAlert as enqueueAlertAction } from 'slices/alerts';
import store from 'store';

const axiosInstance = Axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
});

const enqueueAlert = (alert: Alert) => store.dispatch(enqueueAlertAction(alert));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error?.response?.data?.message || 'Something went wrong, try again later.';

    enqueueAlert({
      message: `Error: ${message}`,
      options: {
        variant: 'error',
      },
    });

    return Promise.reject(error.response || error.message);
  },
);

const axiosUploadInstance = Axios.create({
  baseURL: BASE_API_URL,
  timeout: 0,
});

axiosUploadInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error?.response?.data?.message || 'Something went wrong, try again later.';

    enqueueAlert({
      message: `Error: ${message}`,
      options: {
        variant: 'error',
      },
    });

    return Promise.reject(error.response || error.message);
  },
);

export { axiosUploadInstance };
export default axiosInstance;
