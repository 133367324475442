import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};
