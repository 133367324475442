import { SnackbarKey, useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from 'slices/alerts';
import { RootState } from 'store';

let displayed = [];

export const AlertNotifier: FC = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((store: RootState) => store.alerts || []);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    alerts.forEach(({ key, message, options = {} }) => {
      if (displayed.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_, snackbarKey: SnackbarKey) => {
          dispatch(removeAlert(snackbarKey as number));
          removeDisplayed(snackbarKey);
        },
      });

      storeDisplayed(key);
    });
  }, [alerts, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};
