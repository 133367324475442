import { Button, Fade, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { useStyles } from './styles';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  href?: string;
  icon?: any;
  open?: boolean;
  title: string;
  openNavbar: boolean;
}

export const NavItem: FC<NavItemProps> = ({
  className,
  href,
  icon: Icon,
  title,
  openNavbar,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf)}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="18" />}
        <Fade in={openNavbar} timeout={{ enter: 600, exit: 200 }}>
          <Typography className={classes.title}>{title}</Typography>
        </Fade>
      </Button>
    </ListItem>
  );
};
