import {
  Palette,
  PaletteColor,
  TypeBackground,
  TypeText,
} from '@material-ui/core/styles/createPalette';

import { THEME_COLORS } from './colors';

interface CustomBackgroundVariants extends TypeBackground {
  default: string;
  paper: string;
  dark: string;
  lightDark: string;
  light: string;
}

export interface CustomMuiPalette extends Partial<Palette> {
  background: CustomBackgroundVariants;
}

export const PALETTE_SETTINGS: CustomMuiPalette = {
  type: 'light',
  background: {
    default: THEME_COLORS.white,
    light: THEME_COLORS.gray,
    dark: THEME_COLORS.brandBlue,
    lightDark: THEME_COLORS.lightGray,
    paper: THEME_COLORS.white,
  },
  primary: {
    main: THEME_COLORS.brandBlue,
  } as PaletteColor,
  secondary: {
    main: THEME_COLORS.brandRed[400],
    dark: THEME_COLORS.brandRed[700],
    light: THEME_COLORS.brandRed[400], // TODO: check light variant
    contrastText: THEME_COLORS.white,
  },
  text: {
    primary: THEME_COLORS.blueGrey[900],
    secondary: THEME_COLORS.blueGrey[600],
  } as TypeText,
};
