import { API_ROUTES } from 'constants/api';
import { User } from 'models/user';
import { GoogleLoginResponse } from 'react-google-login';
import axiosInstance from 'utils/axios';

export class AuthService {
  static async authWithGoogle(googleLoginResponse: GoogleLoginResponse): Promise<User> {
    const { data } = await axiosInstance.post(API_ROUTES.AUTH_WITH_GOOGLE, {
      tokenId: googleLoginResponse.tokenId,
      accessToken: googleLoginResponse.accessToken,
    });

    return data;
  }

  static async getUserProfile(): Promise<User> {
    const { data } = await axiosInstance.get<User>(API_ROUTES.USER_PROFILE);
    return data;
  }
}
