import { AuthGuard } from 'components/AuthGuard';
import { GuestGuard } from 'components/GuestGuard';
import { RenderableRoute } from 'components/RouteRenderer';
import { APP_ROUTES } from 'constants/routes';
import DashboardLayout from 'layouts/DashboardLayout';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

export const ApplicationRoutes: Array<RenderableRoute> = [
  {
    exact: true,
    guard: GuestGuard,
    path: APP_ROUTES.LOGIN.path,
    component: lazy(() => import('views/auth/LoginView')),
  },
  {
    path: APP_ROUTES.DASHBOARD.path,
    guard: AuthGuard,
    layout: DashboardLayout,
    exact: false,
    breadcrumb: APP_ROUTES.DASHBOARD.breadcrumb,
    routes: [
      {
        exact: true,
        path: APP_ROUTES.DASHBOARD.path,
        component: lazy(() => import('views/account/AccountView')),
      },
      {
        exact: true,
        path: APP_ROUTES.ACCOUNT.path,
        component: lazy(() => import('views/account/AccountView')),
      },
      {
        exact: false,
        path: APP_ROUTES.FLOWS.path,
        layout: lazy(() => import('views/flows/FlowsView')),
        breadcrumb: APP_ROUTES.FLOWS.breadcrumb,
        routes: [
          {
            exact: true,
            path: APP_ROUTES.FLOWS.path,
            redirectTo: APP_ROUTES.FLOWS_ASSIGNED_TO_ME.path,
          },
          {
            exact: true,
            path: APP_ROUTES.FLOWS_FOLLOWING.path,
            component: lazy(() => import('views/flows/FlowsView/FollowingView')),
          },
          {
            exact: true,
            path: APP_ROUTES.FLOWS_ASSIGNED_TO_ME.path,
            component: lazy(() => import('views/flows/FlowsView/AssignedToMeView')),
          },
          {
            exact: false,
            path: APP_ROUTES.FLOW_EXECUTING_DETAILS.path,
            component: lazy(() => import('views/flows/FlowsView/RunningFlowDetails')),
          },
        ],
      },
      {
        exact: false,
        path: APP_ROUTES.COMPANY_SETUP.path,
        layout: lazy(() => import('views/companySetup/CompanySetupView')),
        breadcrumb: APP_ROUTES.COMPANY_SETUP.breadcrumb,
        routes: [
          {
            exact: true,
            path: APP_ROUTES.COMPANY_SETUP.path,
            redirectTo: APP_ROUTES.COMPANY_SETUP_FLOW_MAPPINGS.path,
          },
          {
            exact: true,
            path: APP_ROUTES.COMPANY_SETUP_FLOW_MAPPINGS.path,
            component: lazy(() => import('views/companySetup/CompanySetupView/FlowMappingsView')),
          },
          {
            exact: true,
            path: APP_ROUTES.COMPANY_SETUP_CREATE_FLOW.path,
            component: lazy(() => import('views/companySetup/CreateFlowView')),
          },
          {
            exact: true,
            path: APP_ROUTES.COMPANY_SETUP_EDIT_FLOW.path,
            component: lazy(() => import('views/companySetup/EditFlowView')),
          },
        ],
      },
      {
        exact: true,
        path: APP_ROUTES.KANBAN.path,
        component: lazy(() => import('views/kanban/KanbanView')),
      },
      {
        exact: true,
        path: APP_ROUTES.KANBAN_BOARD.path,
        component: lazy(() => import('views/kanban/KanbanBoardView')),
      },
      {
        component: () => <Redirect to={APP_ROUTES.NOT_FOUND} />,
      },
    ],
  },
  {
    exact: false,
    path: APP_ROUTES.NOT_FOUND.path,
    component: lazy(() => import('views/errors/NotFoundView')),
  },
];
