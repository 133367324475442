import { makeStyles } from '@material-ui/core';
import type { AppTheme } from 'theme';

export const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginTop: 64,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 52,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.light,
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));
