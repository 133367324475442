import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as alertsReducer } from 'slices/alerts';
import { reducer as kanbanReducer } from 'slices/kanban';
import { reducer as notificationReducer } from 'slices/notification';

const rootReducer = combineReducers({
  form: formReducer,
  notifications: notificationReducer,
  alerts: alertsReducer,
  kanban: kanbanReducer,
});

export default rootReducer;
