import { AppBar, Hidden, IconButton, makeStyles, SvgIcon, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import { THEMES } from 'constants/app';
import type { FC } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import type { AppTheme } from 'theme';

interface TopBarProps {
  className?: string;
  onNavOpen?: () => void;
}

const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar: FC<TopBarProps> = ({ className, onNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
export default TopBar;
