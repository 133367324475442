import { Box, Grid, Paper } from '@material-ui/core';
import { HeaderWithBreadcrumbs } from 'components/HeaderWithBreadcrumbs';
import { FC } from 'react';

import { Account, Notifications } from './UserActions';

export const PageHeader: FC = () => {
  return (
    <Paper elevation={5} square variant="outlined">
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <HeaderWithBreadcrumbs />
        <Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            wrap="nowrap"
            spacing={1}
          >
            <Grid item>
              <Notifications />
            </Grid>
            <Grid item>
              <Box ml={1}>
                <Account />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
