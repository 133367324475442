import { Typography } from '@material-ui/core/styles/createTypography';

const FONTS = {
  PRIMARY: 'Poppins',
  SECONDARY: 'Open Sans',
};

export const TYPOGRAPHY_SETTINGS: Partial<Typography> = {
  fontFamily: `${FONTS.PRIMARY}, ${FONTS.SECONDARY}`,
  h1: {
    fontWeight: 400,
    fontSize: 34,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: 400,
    fontSize: 30,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 400,
    fontSize: 24,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '0.11px',
  },
  body1: {
    fontFamily: `${FONTS.SECONDARY}, sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.15px',
  },
  body2: {
    fontFamily: `${FONTS.SECONDARY}, sans-serif`,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
  },
  overline: {
    fontFamily: `${FONTS.SECONDARY}, sans-serif`,
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '1.3px',
  },
  caption: {
    fontFamily: `${FONTS.SECONDARY}, sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: '0.5px',
  },
  button: {
    fontFamily: `${FONTS.SECONDARY}, sans-serif`,
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '0.45px',
  },
};
