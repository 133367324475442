import { makeStyles } from '@material-ui/core';
import type { AppTheme } from 'theme';

export const useStyles = makeStyles((theme: AppTheme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));
