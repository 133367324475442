import { Box, LinearProgress } from '@material-ui/core';
import NProgress from 'nprogress';
import { FC, useEffect } from 'react';

import { useStyles } from './styles';

export const LoadingScreen: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
