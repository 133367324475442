import { SplashScreen } from 'components/SplashScreen';
import type { User } from 'models/user';
import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import { GoogleLoginResponse as IGoogleLoginResponse } from 'react-google-login';
import { AuthService } from 'services/http/auth/auth.service';

import { SessionService } from '../services/session/session.service';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface GoogleAuthContextValue extends AuthState {
  method: 'Google';
  login: (googleLoginResponse: IGoogleLoginResponse) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const GoogleAuthContext = createContext<GoogleAuthContextValue>({
  ...initialAuthState,
  method: 'Google',
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (googleLoginResponse: IGoogleLoginResponse) => {
    const { accessToken, ...user } = await AuthService.authWithGoogle(googleLoginResponse);

    SessionService.setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    SessionService.revokeSession();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async (): Promise<void> => {
      try {
        const accessToken = SessionService.getSession();

        if (accessToken && SessionService.checkValidToken(accessToken)) {
          SessionService.setSession(accessToken);
          const user = await AuthService.getUserProfile();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <GoogleAuthContext.Provider
      value={{
        ...state,
        method: 'Google',
        login,
        logout,
      }}
    >
      {children}
    </GoogleAuthContext.Provider>
  );
};

export default GoogleAuthContext;
