const messages = {
  pt: {
    translations: {
      profile: 'Perfil',
      'Assigned to me': 'Atribuídos a mim',
      Account: 'Conta',
      following: 'Seguindo',
      Following: 'Seguindo',
      buttons: {
        save: 'salvar',
        saveChanges: 'Salvar alterações',
        startFlow: 'executar um flow',
        createFlow: 'criar novo flow',
        continue: 'continuar',
        assignTo: 'atribuir à',
        cancel: 'cancelar',
      },
    },
  },
};

export { messages };
