import DateFnsUtils from '@date-io/date-fns';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AlertNotifier } from 'components/AlertNotifier';
import { GlobalStyles } from 'components/GlobalStyles';
import { RouteRenderer } from 'components/RouteRenderer';
import { AuthProvider } from 'contexts/GoogleAuthContext';
import useSettings from 'hooks/useSettings';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApplicationRoutes } from 'routes';
import smartlookClient from 'smartlook-client';
import { createTheme } from 'theme';

import { ScrollReset } from './components/ScrollReset';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

if (process.env.NODE_ENV === 'production') {
  smartlookClient.init(process.env.REACT_APP_SMARTLOOK_CLIENT_KEY);

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <AlertNotifier />
            <Router>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {RouteRenderer(ApplicationRoutes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
