import { makeStyles } from '@material-ui/core';
import type { AppTheme } from 'theme';

export const useStyles = makeStyles<AppTheme>((theme: AppTheme) => ({
  mobileDrawer: {
    backgroundColor: theme.palette.primary.main,
    width: 256,
  },
  desktopDrawer: (props: any) => ({
    backgroundColor: theme.palette.primary.main,
    height: 'calc(100%)',
    width: props.openNavbar ? 200 : 52,
    transition: 'width .3s',
  }),
  backdrop: {
    backdropFilter: 'blur(1px)',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'left .3s',
    zIndex: 1301,
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 17px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.primary.contrastText,
    padding: '0 17px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 'auto',
    fontWeight: 600,
    fontSize: 14,
  },
}));
