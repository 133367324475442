import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import { Typography } from '@material-ui/core/styles/createTypography';
import { THEMES } from 'constants/app';

import {
  OVERRIDES_SETTINGS,
  PALETTE_SETTINGS,
  PROPS_SETTINGS,
  SHADOW_SETTINGS,
  TYPOGRAPHY_SETTINGS,
} from './settings';
import { CustomMuiPalette } from './settings/palette';

export interface ThemeConfig {
  responsiveFontSizes?: boolean;
  theme?: string;
}

export interface AppTheme extends Partial<Omit<MuiTheme, 'palette'>> {
  name?: string;
  palette: CustomMuiPalette;
}

export const THEME: AppTheme = {
  name: THEMES.LIGHT,
  typography: TYPOGRAPHY_SETTINGS as Typography,
  palette: PALETTE_SETTINGS,
  shadows: SHADOW_SETTINGS,
  props: PROPS_SETTINGS,
  overrides: OVERRIDES_SETTINGS,
};
