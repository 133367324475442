import { Box, LinearProgress } from '@material-ui/core';
import type { FC } from 'react';

import { useStyles } from './styles';

export const SplashScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};
