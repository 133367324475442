import { User } from 'models/user';
import axiosInstance from 'utils/axios';

export class CollaboratorsService {
  static async getCollaboratorsByFullName(fullName: string): Promise<Array<User>> {
    const { data } = await axiosInstance.get('/collaborators', {
      params: {
        fullName,
      },
    });
    return data;
  }

  static async getAllCollaborators(): Promise<Array<User>> {
    const { data } = await axiosInstance.get('/collaborators/all');

    return data;
  }
}
