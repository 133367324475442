import { LoadingScreen } from 'components/LoadingScreen';
import { Fragment, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RenderableRoute } from './interfaces';

export const RouteRenderer = (routes: Array<RenderableRoute>): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const redirectToPath = route.redirectTo;

        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    RouteRenderer(route.routes)
                  ) : redirectToPath ? (
                    <Redirect to={redirectToPath} />
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
