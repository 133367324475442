import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import { AppTheme, THEME, ThemeConfig } from './theme';

export const createTheme = (config: ThemeConfig = {}): AppTheme => {
  let theme = createMuiTheme(THEME);
  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }
  return theme as AppTheme;
};
