import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { OptionsObject } from 'notistack';
import { AppThunk } from 'store';

export interface Alert {
  key?: number;
  message: string;
  options: OptionsObject;
}

type AlertsState = Array<Alert>;

const initialState: AlertsState = [];

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    enqueueAlert(state: AlertsState, action: PayloadAction<{ alert: Alert }>): AlertsState {
      const { alert } = action.payload;
      return [
        ...state,
        {
          key: new Date().getTime() + Math.random(),
          ...alert,
        },
      ];
    },
    removeAlert(state: AlertsState, action: PayloadAction<{ key: number }>): AlertsState {
      const { key } = action.payload;
      return [...state.filter((alert) => alert.key !== key)];
    },
  },
});

export const reducer = slice.reducer;

export const enqueueAlert =
  (alert: Alert): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.enqueueAlert({
        alert,
      }),
    );
  };

export const removeAlert =
  (key: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeAlert({ key }));
  };

export default slice;
