import { Overrides } from '@material-ui/core/styles/overrides';

export const OVERRIDES_SETTINGS: Overrides = {
  MuiFormHelperText: {
    root: {
      fontWeight: 400,
      letterSpacing: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 0,
      marginRight: 16,
    },
  },
  MuiList: {
    root: {
      minWidth: 200,
    },
  },
  MuiGrid: {
    root: {
      outline: 'none',
    },
  },
};
