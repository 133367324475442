export const BASE_API_URL = process.env.REACT_APP_API_URL;

const AUTH_ROUTES = {
  AUTH_WITH_GOOGLE: '/collaborators/auth/google',
  USER_PROFILE: '/collaborators/auth/me',
};

const FLOW_ROUTES = {
  FLOWS: '/flows',
  GET_FLOW: '/flows/:id',
  EDIT_FLOWS: '/flows/:id/edit',
  ACTIVATE_FLOW: '/flows/:id/activate',
  DEACTIVATE_FLOW: '/flows/:id/deactivate',
  DELETE_FLOW: '/flows/:id',

  START_FLOW: '/flows/execution',
  EXECUTING_FLOW: '/flows/execution/:id',
  EXECUTING_FLOW_RESOLVE_STEP: '/flows/execution/:id/step/:stepNumber',
  UPDATE_STATUS_FLOW_EXECUTION: '/flows/execution/:id/status',
  GET_ASSIGNED_TO_ME_FLOW_EXECUTIONS: '/flows/execution/assigned-to-me',
  GET_FOLLOWING_EXECUTIONS: '/flows/execution/following',
  FOLLOW_EXECUTING_FLOW: '/flows/execution/:id/follow',
  UNFOLLOW_EXECUTING_FLOW: '/flows/execution/:id/unfollow',
  TRANSFER_ASSIGNED_COLLABORATOR: '/flows/execution/:id/transfer-assigned-collaborator/:stepNumber',
  RETURN_STEP: '/flows/execution/:id/return-step',
};

const KANBAN_ROUTES = {
  KANBANS: '/kanban',
  GET_KANBAN: '/kanban/:kanbanId',
  UPDATE_KANBAN_TITLE: '/kanban/:id/update',
  DELETE_KANBAN: '/kanban/:id',

  CREATE_LIST_KANBAN: '/kanban/:kanbanId/create-list',
  UPDATE_LIST_KANBAN: '/kanban/:kanbanId/list/update',
  DELETE_LIST_KANBAN: '/kanban/:kanbanId/list/:listId/delete',
  MOVE_LIST_KANBAN: '/kanban/:kanbanId/list/move',

  CREATE_CARD_KANBAN: '/kanban/:kanbanId/list/:listId/create-card',
  DELETE_CARD_KANBAN: '/kanban/:kanbanId/card/delete',
  UPDATE_CARD_KANBAN: '/kanban/:kanbanId/card/update',
  MOVE_CARD_KANBAN: '/kanban/:kanbanId/card/move',

  ADD_COMMENT: '/kanban/:kanbanId/comments/create',
  DELETE_COMMENT: '/kanban/:kanbanId/comments/delete',
  UPDATE_COMMENT: '/kanban/:kanbanId/comments/update',

  ADD_CHECKLIST: '/kanban/:kanbanId/checklist/create',
  DELETE_CHECKLIST: '/kanban/:kanbanId/checklist/delete',
  UPDATE_CHECKLIST: '/kanban/:kanbanId/checklist/update',

  ADD_CHECKITEM: '/kanban/:kanbanId/checkitem/create',
  DELETE_CHECKITEM: '/kanban/:kanbanId/checkitem/delete',
  UPDATE_CHECKITEM: '/kanban/:kanbanId/checkitem/update',

  ADD_ATTACHMENT: '/kanban/:kanbanId/attachment/create',
  DELETE_ATTACHMENT: '/kanban/:kanbanId/attachment/delete',
  UPDATE_ATTACHMENT: '/kanban/:kanbanId/attachment/update',

  ADD_COLLABORATOR: '/kanban/:kanbanId/collaborator/add',
  DELETE_COLLABORATOR: '/kanban/:kanbanId/collaborator/delete',

  CREATE_LABEL: '/kanban/:kanbanId/label/create',
  DELETE_LABEL: '/kanban/:kanbanId/label/delete',
  UPDATE_LABEL: '/kanban/:kanbanId/label/update',
  ASSIGN_LABEL: '/kanban/:kanbanId/label/assign',
  UNASSIGN_LABEL: '/kanban/:kanbanId/label/unassign',
};

export const API_ROUTES = {
  ...AUTH_ROUTES,
  ...FLOW_ROUTES,
  ...KANBAN_ROUTES,
};
