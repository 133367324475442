import { CLIENT_STORAGE_APP_KEY_PREFIX } from 'constants/app';
import jwtDecode from 'jwt-decode';
import axios from 'utils/axios';

export class SessionService {
  static JWT_TOKEN_KEY = [CLIENT_STORAGE_APP_KEY_PREFIX, 'access-token'].join('-');

  static setSession(accessToken: string): void {
    localStorage.setItem(SessionService.JWT_TOKEN_KEY, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  static revokeSession(): void {
    localStorage.removeItem(SessionService.JWT_TOKEN_KEY);
    delete axios.defaults.headers.common.Authorization;
  }

  static getSession(): string {
    return window.localStorage.getItem(SessionService.JWT_TOKEN_KEY);
  }

  static checkValidToken(accessToken: string): boolean {
    if (!accessToken) {
      return false;
    }

    const decoded: any = jwtDecode(accessToken);

    if (decoded.exp * 1000 < new Date().getTime()) {
      return false;
    }

    return true;
  }
}
