import 'typeface-open-sans';
import 'typeface-poppins';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import '__mocks__';
import './translate';

import App from 'App';
import { SettingsProvider } from 'contexts/SettingsContext';
import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import store from 'store';

enableES5();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
