import React, { FC } from 'react';

export enum ELogoVariants {
  DEFAULT,
  WHITE,
  COLORED,
}

interface ILogoProps {
  maxWidth?: number;
  variant?: ELogoVariants;
}

export const Logo: FC<ILogoProps> = ({ maxWidth = 100, variant = ELogoVariants.DEFAULT }) => {
  const IMAGE_VARIANTS = {
    [ELogoVariants.WHITE]: '/static/logo-white.svg',
    [ELogoVariants.COLORED]: '/static/logo-colored.svg',
    [ELogoVariants.DEFAULT]: '/static/logo.svg',
  };

  return (
    <img
      alt="Logo"
      src={IMAGE_VARIANTS[variant]}
      style={{
        maxWidth,
        width: '100%',
      }}
    />
  );
};
