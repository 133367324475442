const messages = {
  en: {
    translations: {
      profile: 'Profile',
      'Assigned to me': 'Assigned to me',
      Account: 'Account',
      Following: 'Following',
      following: 'Following',
      buttons: {
        save: 'save',
        saveChanges: 'Save Changes',
        startFlow: 'start a new flow',
        createFlow: 'create a new flow',
        continue: 'continue',
        assignTo: 'assign to',
        cancel: 'cancel',
      },
    },
  },
};

export { messages };
