import { API_ROUTES } from 'constants/api';
import { Label } from 'models/kanban';
import axiosInstance from 'utils/axios';

export class KanbansService {
  static async createKanban(title: string): Promise<any> {
    const { data } = await axiosInstance.post(API_ROUTES.KANBANS, {
      title,
    });
    return data;
  }

  static async deleteKanban(kanbanId: string): Promise<void> {
    await axiosInstance.delete(API_ROUTES.DELETE_KANBAN.replace(':id', kanbanId));
  }

  static async getKanbans(): Promise<any> {
    const { data } = await axiosInstance.get(API_ROUTES.KANBANS);
    return data;
  }

  static async updateKanban(kanbanId: string, title: string): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_KANBAN_TITLE.replace(':id', kanbanId),
      { title: title },
    );

    return data;
  }

  static async getKanban(kanbanId: string): Promise<any> {
    const { data } = await axiosInstance.get(API_ROUTES.GET_KANBAN.replace(':kanbanId', kanbanId));
    return data;
  }

  static async createList(kanbanId: string, title: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.CREATE_LIST_KANBAN.replace(':kanbanId', kanbanId),
      { title: title },
    );
    return data;
  }

  static async updateList(kanbanId: string, listId: string, update: any): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_LIST_KANBAN.replace(':kanbanId', kanbanId),
      { ...update, listId },
    );

    return data;
  }

  static async deleteList(kanbanId: string, listId: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_LIST_KANBAN.replace(':kanbanId', kanbanId).replace(':listId', listId),
    );
    return data;
  }

  static async moveList(
    kanbanId: string,
    position: number,
    previousPosition: number,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.MOVE_LIST_KANBAN.replace(':kanbanId', kanbanId),
      {
        position,
        previousPosition,
      },
    );
    return data;
  }

  static async createCard(kanbanId: string, listId: string, title: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.CREATE_CARD_KANBAN.replace(':kanbanId', kanbanId).replace(':listId', listId),
      { title: title },
    );
    return data;
  }

  static async deleteCard(kanbanId: string, listId: string, cardId: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_CARD_KANBAN.replace(':kanbanId', kanbanId),
      { listId, cardId },
    );
    return data;
  }

  static async updateCard(kanbanId: string, cardId: string, update: any): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_CARD_KANBAN.replace(':kanbanId', kanbanId),
      { ...update, cardId },
    );

    return data;
  }

  static async moveCard(
    kanbanId: string,
    cardId: string,
    position: number,
    listId: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.MOVE_CARD_KANBAN.replace(':kanbanId', kanbanId),
      {
        cardId,
        position,
        listId,
      },
    );
    return data;
  }

  static async addComment(kanbanId: string, cardId: string, message: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.ADD_COMMENT.replace(':kanbanId', kanbanId),
      { cardId, message },
    );
    return data;
  }

  static async deleteComment(kanbanId: string, cardId: string, commentId: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_COMMENT.replace(':kanbanId', kanbanId),
      {
        cardId,
        commentId,
      },
    );
    return data;
  }

  static async updateComment(
    kanbanId: string,
    cardId: string,
    commentId: any,
    message: string,
  ): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_COMMENT.replace(':kanbanId', kanbanId),
      { cardId, commentId, message },
    );

    return data;
  }

  static async addChecklist(kanbanId: string, cardId: string, name: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.ADD_CHECKLIST.replace(':kanbanId', kanbanId),
      { cardId, name },
    );
    return data;
  }

  static async deleteChecklist(
    kanbanId: string,
    cardId: string,
    checklistId: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_CHECKLIST.replace(':kanbanId', kanbanId),
      {
        cardId,
        checklistId,
      },
    );
    return data;
  }

  static async updateChecklist(
    kanbanId: string,
    cardId: string,
    checklistId: string,
    update: any,
  ): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_CHECKLIST.replace(':kanbanId', kanbanId),
      { cardId, checklistId, ...update },
    );

    return data;
  }

  static async addCheckItem(
    kanbanId: string,
    cardId: string,
    checklistId: string,
    name: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.ADD_CHECKITEM.replace(':kanbanId', kanbanId),
      { cardId, checklistId, name },
    );
    return data;
  }

  static async deleteCheckItem(
    kanbanId: string,
    cardId: string,
    checklistId: string,
    checkItemId: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_CHECKITEM.replace(':kanbanId', kanbanId),
      {
        cardId,
        checklistId,
        checkItemId,
      },
    );
    return data;
  }

  static async updateCheckItem(
    kanbanId: string,
    cardId: string,
    checklistId: string,
    checkItemId: string,
    update: any,
  ): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_CHECKITEM.replace(':kanbanId', kanbanId),
      { cardId, checklistId, checkItemId, ...update },
    );

    return data;
  }

  static async addCollaborator(kanbanId: string, cardId: string, collaborator: any): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.ADD_COLLABORATOR.replace(':kanbanId', kanbanId),
      { cardId, collaborator },
    );
    return data;
  }

  static async deleteCollaborator(
    kanbanId: string,
    cardId: string,
    collaboratorId: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_COLLABORATOR.replace(':kanbanId', kanbanId),
      {
        cardId,
        collaboratorIdCard: collaboratorId,
      },
    );
    return data;
  }

  static async createLabel(
    kanbanId: string,
    cardId: string,
    name: string,
    color: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.CREATE_LABEL.replace(':kanbanId', kanbanId),
      { cardId, name, color },
    );
    return data;
  }

  static async deleteLabel(kanbanId: string, cardId: string, labelId: string): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_LABEL.replace(':kanbanId', kanbanId),
      {
        cardId,
        labelId,
      },
    );
    return data;
  }

  static async updateLabel(
    kanbanId: string,
    cardId: string,
    labelId: string,
    update: Partial<Label>,
  ): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_LABEL.replace(':kanbanId', kanbanId),
      {
        cardId,
        labelId,
        update,
      },
    );
    return data;
  }

  static async assignLabel(kanbanId: string, cardId: string, labelId: string): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.ASSIGN_LABEL.replace(':kanbanId', kanbanId),
      {
        cardId,
        labelId,
      },
    );
    return data;
  }

  static async unassignLabel(kanbanId: string, cardId: string, labelId: string): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UNASSIGN_LABEL.replace(':kanbanId', kanbanId),
      {
        cardId,
        labelId,
      },
    );
    return data;
  }

  static async addAttachment(
    kanbanId: string,
    cardId: string,
    originalFileName: string,
    file: string,
    size: number,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.ADD_ATTACHMENT.replace(':kanbanId', kanbanId),
      { cardId, originalFileName, file, size },
    );
    return data;
  }

  static async deleteAttachment(
    kanbanId: string,
    cardId: string,
    attachmentId: string,
  ): Promise<any> {
    const { data } = await axiosInstance.post(
      API_ROUTES.DELETE_ATTACHMENT.replace(':kanbanId', kanbanId),
      {
        cardId,
        attachmentId,
      },
    );
    return data;
  }

  static async updateAttachment(
    kanbanId: string,
    cardId: string,
    attachmentId: string,
    update: any,
  ): Promise<any> {
    const { data } = await axiosInstance.patch(
      API_ROUTES.UPDATE_ATTACHMENT.replace(':kanbanId', kanbanId),
      { cardId, attachmentId, ...update },
    );

    return data;
  }
}
