import { JSONSchema7 } from 'json-schema';

import { User } from './user';

export enum EFormFieldTypes {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  MULTIPLE_FILES = 'MULTIPLE_FILES',
}

export enum EFlowStepEventIgnitors {
  MANUAL_INTERACTION_EVENT = 'MANUAL_INTERACTION_EVENT',
  COLLABORATOR_ADDED_EVENT = 'COLLABORATOR_ADDED_EVENT',
}

interface FlowStepNotifications {
  collaborators: Array<User>;
}

export interface IFieldInput {
  jsonSchema: JSONSchema7;
  uiSchema?: any;
}

export interface FlowStep {
  id?: number;
  title: string;
  description: string;
  eventIgnitor: string;
  input?: IFieldInput;
  notifications: FlowStepNotifications;
}

export enum EFlowStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type Creator = Pick<User, 'id' | 'name'>;

export interface Flow {
  id?: string;
  title: string;
  description: string;
  creator?: Creator;
  steps: Array<FlowStep>;
  status?: EFlowStatuses;
  createdAt?: Date;
}
