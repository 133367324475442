/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import {
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Drawer,
  Fade,
  Hidden,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ELogoVariants, Logo } from 'components/Logo';
import { APP_ROUTES } from 'constants/routes';
import useSettings from 'hooks/useSettings';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import {
  Briefcase as BriefcaseIcon,
  GitPullRequest as GitPullRequestIcon,
  Grid as GridIcon,
  HelpCircle as HelpCircleIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';

import { NavItem } from './NavItem';
import { useStyles } from './styles';

interface NavBarProps {
  onNavOpen: () => void;
  onClose: () => void;
  openNavbar: boolean;
}

interface Item {
  path?: string;
  icon?: ReactNode;
  title: string;
}

const sections: Item[] = [
  {
    title: 'Home',
    path: APP_ROUTES.ACCOUNT.path,
    icon: HomeIcon,
  },
  {
    title: 'Flows',
    path: APP_ROUTES.FLOWS.path,
    icon: GitPullRequestIcon,
  },
  {
    title: 'Company Setup',
    path: APP_ROUTES.COMPANY_SETUP.path,
    icon: BriefcaseIcon,
  },
  {
    title: 'Kanban',
    path: APP_ROUTES.KANBAN.path,
    icon: GridIcon,
  },
];

function renderNavItems({ item, openNavbar }: { item: Item; openNavbar: boolean }) {
  return (
    <NavItem
      key={item.title}
      href={item.path}
      icon={item.icon}
      title={item.title}
      openNavbar={openNavbar}
    />
  );
}

const NavBar: FC<NavBarProps> = ({ onNavOpen, onClose, openNavbar }) => {
  const classes = useStyles({ openNavbar });
  const { settings } = useSettings();

  useEffect(() => {
    if (openNavbar && onClose) {
      onClose();
    }
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} marginLeft="17px" display="flex" flexDirection="row">
          <Box height={36}>
            <Fade in={openNavbar} timeout={{ enter: 600, exit: 200 }}>
              <Box>
                <RouterLink to="/app">
                  <Logo
                    maxWidth={93}
                    variant={
                      settings.theme === 'LIGHT' ? ELogoVariants.DEFAULT : ELogoVariants.COLORED
                    }
                  />
                </RouterLink>
              </Box>
            </Fade>
          </Box>
          <Box position="absolute" right={16} top={25}>
            <ButtonBase onClick={onNavOpen}>
              <MenuIcon color="white" size="18" />
            </ButtonBase>
          </Box>
        </Box>
        <Box pt={2}>
          <List disablePadding>
            {sections?.map((item: Item) => renderNavItems({ item, openNavbar }))}
          </List>
        </Box>
        <Box position="absolute" bottom="0" mb={2} width="100%">
          <List key="tooltip-list" disablePadding>
            <ListItem className={clsx(classes.itemLeaf)} disableGutters>
              <Button
                className={clsx(classes.buttonLeaf)}
                component={Link}
                target="_blank"
                href="https://toolbox.hero99.guide/"
                rel="noopener noreferrer"
                underline="none"
              >
                <HelpCircleIcon className={classes.icon} size="18" />
                <Fade in={openNavbar} timeout={{ enter: 600, exit: 200 }}>
                  <Typography className={classes.title}>Hero tooltip</Typography>
                </Fade>
              </Button>
            </ListItem>
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onClose}
          open={openNavbar}
          variant="temporary"
          BackdropProps={{
            classes: {
              root: openNavbar ? classes.backdrop : null,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <ClickAwayListener onClickAway={onClose}>
          <Box zIndex={1302} position="absolute" left={0} className={classes.desktopDrawer}>
            {content}
          </Box>
        </ClickAwayListener>
        {openNavbar ? <Box className={classes.overlay} /> : null}
      </Hidden>
    </>
  );
};

export default NavBar;
