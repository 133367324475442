export interface IAppRouteDeclaration {
  path: string;
  breadcrumb?: string;
}

export const APP_ROUTES: { [key: string]: IAppRouteDeclaration } = {
  LOGIN: {
    path: '/',
  },
  DASHBOARD: {
    path: '/app',
    breadcrumb: 'Home',
  },
  ACCOUNT: {
    path: '/app/account',
    breadcrumb: 'Account',
  },
  FLOWS: {
    path: '/app/flows',
    breadcrumb: 'Flows',
  },
  FLOWS_ASSIGNED_TO_ME: {
    path: '/app/flows/assigned-to-me',
    breadcrumb: 'Assigned to me',
  },
  FLOWS_FOLLOWING: {
    path: '/app/flows/following',
    breadcrumb: 'Following',
  },
  FLOW_EXECUTING_DETAILS: {
    path: '/app/flows/:flowId',
    breadcrumb: 'Details',
  },
  COMPANY_SETUP: {
    path: '/app/company-setup',
    breadcrumb: 'Setup',
  },
  COMPANY_SETUP_FLOW_MAPPINGS: {
    path: '/app/company-setup/flow-mappings',
    breadcrumb: 'Flow Mappings',
  },
  COMPANY_SETUP_CREATE_FLOW: {
    path: '/app/company-setup/flow-mappings/create',
    breadcrumb: 'Create Flow Mapping',
  },
  COMPANY_SETUP_EDIT_FLOW: {
    path: '/app/company-setup/flow-mappings/:flowId/edit',
    breadcrumb: 'Edit Flow Mapping',
  },
  KANBAN: {
    path: '/app/kanban',
    breadcrumb: 'Kanban',
  },
  KANBAN_BOARD: {
    path: '/app/kanban/:kanbanId',
    breadcrumb: 'Board',
  },
  NOT_FOUND: {
    path: '/404',
  },
};
