import { makeStyles } from '@material-ui/core';
import type { AppTheme } from 'theme';

export const useStyles = makeStyles((theme: AppTheme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 17px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
    padding: '10px 17px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 'auto',
    fontWeight: 600,
    fontSize: 14,
  },
  active: {
    color: theme.palette.primary.contrastText,
    opacity: 1,
  },
}));
