import { Box, Hidden } from '@material-ui/core';
import { PageHeader } from 'components/PageHeader';
import { GOOGLE } from 'config';
import { FC, ReactNode, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import NavBar from './NavBar';
import { useStyles } from './styles';
import TopBar from './TopBar';

interface DashboardLayoutProps {
  children?: ReactNode;
}

ReactGA.initialize(GOOGLE.GA_TRACKING_CODE);

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <TopBar onNavOpen={(): void => setNavOpen(true)} />
      </Hidden>
      <NavBar
        onNavOpen={(): void => setNavOpen(!isNavOpen)}
        onClose={(): void => setNavOpen(false)}
        openNavbar={isNavOpen}
      />
      <Box className={classes.wrapper} flexDirection="column">
        <PageHeader />
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </Box>
    </div>
  );
};

export default DashboardLayout;
