export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

/**
 * Key prefix to add any item to any kind of storages.
 */
export const CLIENT_STORAGE_APP_KEY_PREFIX = '@hero99-io';

export const THEMES = {
  LIGHT: 'LIGHT',
};
