import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { APP_ROUTES } from 'constants/routes';
import { flatten } from 'ramda';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';

interface IHeaderProps {
  className?: string;
}

const options = { excludePaths: ['/'] };

const treatedRoutes = Object.keys(APP_ROUTES)
  .map((key) => APP_ROUTES[key])
  .filter((route) => route.breadcrumb)
  .map((route) => route);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const HeaderWithBreadcrumbs: FC<IHeaderProps> = ({ className, ...rest }) => {
  const breadcrumbs = useBreadcrumbs(flatten(treatedRoutes), options) as Array<BreadcrumbData>;
  const location = useLocation();
  const currentRoute = treatedRoutes.find((route) => route.path.startsWith(location.pathname));
  const [t] = useTranslation();

  // Remove current page breadcrumb
  breadcrumbs.pop();

  const query = useQuery();

  const getCurrentRoute = query.get('vn');

  return (
    <div className={clsx(className)} {...rest}>
      <Typography variant="h3" color="textPrimary">
        {currentRoute?.breadcrumb ? t(currentRoute.breadcrumb) : getCurrentRoute}
      </Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs.map(({ match, breadcrumb }, index) => (
          <div key={match.url}>
            {breadcrumbs.length === index + 1 && !getCurrentRoute ? (
              <Typography color="textPrimary">{breadcrumb}</Typography>
            ) : (
              <Link color="inherit" to={match.url} component={RouterLink}>
                {breadcrumb}
              </Link>
            )}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};
