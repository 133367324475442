import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { Attachment, Card, CheckItem, Checklist, Comments, Label, List } from 'models/kanban';
import type { User } from 'models/user';
import { CollaboratorsService } from 'services/http/collaborators/collaborators.service';
import { KanbansService } from 'services/http/kanbans/kanbans.service';
import type { AppThunk } from 'store';
import axios from 'utils/axios';
import objFromArray from 'utils/objFromArray';
import { v4 as uuidv4 } from 'uuid';

interface KanbanState {
  isLoaded: boolean;
  projectId?: string;
  lists: {
    byId: Record<string, List> | any;
    allIds: string[];
  };
  cards: {
    byId: Record<string, Card> | any;
    allIds: string[];
  };
  collaborators: {
    byId: Record<string, User> | any;
    allIds: string[];
  };
  labels: {
    byId: Record<string, Label> | any;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  isLoaded: false,
  projectId: null,
  lists: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
  collaborators: {
    byId: {},
    allIds: [],
  },
  labels: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    getBoard(state: KanbanState, action: PayloadAction<{ board: any; allCollaborators: any }>) {
      const { board, allCollaborators } = action.payload;

      state.projectId = board?.projectId;
      state.lists.byId = objFromArray(board.lists);
      state.lists.allIds = Object.keys(state.lists.byId);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      if (allCollaborators) {
        state.collaborators.byId = objFromArray(allCollaborators, 'id');
      } else {
        state.collaborators.byId = objFromArray(board.collaborators);
      }
      state.collaborators.allIds = Object.keys(state.collaborators);
      state.labels.byId = objFromArray(board.labels);
      state.labels.allIds = Object.keys(state.labels);
      state.isLoaded = true;
    },
    createList(state: KanbanState, action: PayloadAction<{ list: List }>) {
      const { list } = action.payload;

      state.lists.byId[list._id] = list;
      state.lists.allIds.push(list._id);
    },
    updateList(state: KanbanState, action: PayloadAction<{ list: List }>) {
      const { list } = action.payload;

      state.lists.byId[list._id] = list;
    },
    clearList(state: KanbanState, action: PayloadAction<{ listId: string }>) {
      const { listId } = action.payload;
      const { cardIds } = state.lists.byId[listId];

      state.lists.byId[listId].cardIds = [];
      state.cards.byId = _.omit(state.cards.byId, cardIds);
      _.pull(state.cards.allIds, ...cardIds);
    },
    deleteList(state: KanbanState, action: PayloadAction<{ listId: string }>) {
      const { listId } = action.payload;

      state.lists.byId = _.omit(state.lists.byId, listId);
      _.pull(state.lists.allIds, listId);
    },
    moveList(
      state: KanbanState,
      action: PayloadAction<{ kanbanId: string; previousPosition: number; position?: number }>,
    ) {
      const { kanbanId, previousPosition, position } = action.payload;

      const previousKey = state.lists.allIds[previousPosition];

      _.pull(state.lists.allIds, previousKey);

      if (kanbanId) {
        state.lists.allIds.splice(position, 0, previousKey);
      }
    },
    createCard(state: KanbanState, action: PayloadAction<{ card: Card; listId: string }>) {
      const { card } = action.payload;

      state.cards.byId[card._id] = card;
      state.cards.allIds.push(card._id);
      state.lists.byId[card.listId].cardIds.push(card._id);
    },
    updateCard(state: KanbanState, action: PayloadAction<{ card: Card }>) {
      const { card } = action.payload;
      _.merge(state.cards.byId[card._id], card);
    },
    moveCard(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; position: number; listId?: string }>,
    ) {
      const { cardId, position, listId } = action.payload;
      const { listId: sourceListId } = state.cards.byId[cardId];

      // Remove card from source list
      _.pull(state.lists.byId[sourceListId].cardIds, cardId);

      // If listId arg exists, it means that
      // we have to add the card to the new list
      if (listId) {
        state.cards.byId[cardId].listId = listId;
        state.lists.byId[listId].cardIds.splice(position, 0, cardId);
      } else {
        state.lists.byId[sourceListId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state: KanbanState, action: PayloadAction<{ cardId: string }>) {
      const { cardId } = action.payload;
      const { listId } = state.cards.byId[cardId];

      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[listId].cardIds, cardId);
    },
    addComment(state: KanbanState, action: PayloadAction<{ comment: Comments }>) {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    deleteComment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; commentId: string }>,
    ) {
      const { cardId, commentId } = action.payload;
      const card = state.cards.byId[cardId];

      card.comments = _.reject(card.comments, { _id: commentId });
    },
    updateComment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; commentId: string; message: string }>,
    ) {
      const { cardId, commentId, message } = action.payload;
      const card = state.cards.byId[cardId];

      card.comments = _.map(card.comments, (comment) => {
        if (comment._id === commentId) {
          _.assign(comment, {
            message: message,
          });
        }

        return comment;
      });
    },
    addAttachment(
      state: KanbanState,
      action: PayloadAction<{
        attachment?: Attachment;
        cardId: string;
        loading: boolean;
        loadingId?: string;
      }>,
    ) {
      const { attachment, cardId, loading, loadingId } = action.payload;
      const card = state.cards.byId[cardId];

      if (loading) {
        card.attachments.push({
          _id: loadingId,
          originalFileName: '',
          file: '',
          size: 0,
          customFileName: '',
        });
      } else {
        if (loadingId) {
          card.attachments = _.map(card.attachments, (_attachment) => {
            if (_attachment._id === loadingId) {
              return attachment;
            }

            return _attachment;
          });
        } else {
          card.attachments.push(attachment);
        }
      }
    },
    deleteAttachment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; attachmentId: string }>,
    ) {
      const { cardId, attachmentId } = action.payload;
      const card = state.cards.byId[cardId];

      card.attachments = _.reject(card.attachments, { _id: attachmentId });
    },
    updateAttachment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; attachment: Attachment }>,
    ) {
      const { cardId, attachment } = action.payload;
      const card = state.cards.byId[cardId];

      card.attachments = _.map(card.attachments, (_attachment) => {
        if (_attachment._id === attachment._id) {
          return attachment;
        }

        return _attachment;
      });
    },
    addChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: Checklist }>,
    ) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    updateChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklist: Checklist }>,
    ) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (_checklist) => {
        if (_checklist._id === checklist._id) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string }>,
    ) {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.reject(card.checklists, { _id: checklistId });
    },
    addCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string; checkItem: CheckItem }>,
    ) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      _.assign(card, {
        checklists: _.map(card.checklists, (checklist) => {
          if (checklist._id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem],
            });
          }

          return checklist;
        }),
      });
    },
    updateCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string; checkItem: CheckItem }>,
    ) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist._id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, (_checkItem) => {
              if (_checkItem._id === checkItem._id) {
                return checkItem;
              }

              return _checkItem;
            }),
          });
        }

        return checklist;
      });
    },
    deleteCheckItem(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; checklistId: string; checkItemId: string }>,
    ) {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist._id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, { _id: checkItemId }),
          });
        }

        return checklist;
      });
    },

    addCollaborator(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; collaborator: any }>,
    ) {
      const { cardId, collaborator } = action.payload;
      const card = state.cards.byId[cardId];

      const collaboratorToAdd = {
        _id: collaborator.id,
        name: collaborator.name,
        email: collaborator.email,
        avatar: collaborator.avatar,
      };

      state.collaborators.byId[collaborator.id] = collaboratorToAdd;
      state.collaborators.allIds.push(collaborator.id);

      card.collaboratorIds.push(collaborator.id);
    },

    deleteCollaborator(
      state: KanbanState,
      action: PayloadAction<{ cardId: string; collaboratorId: string }>,
    ) {
      const { cardId, collaboratorId } = action.payload;
      const card = state.cards.byId[cardId];
      card.collaboratorIds = _.pull(card.collaboratorIds, collaboratorId);
    },

    createLabel(state: KanbanState, action: PayloadAction<{ label: Label; cardId: string }>) {
      const { label, cardId } = action.payload;
      const card = state.cards.byId[cardId];

      card.labelIds.push(label._id);
      state.labels.byId[label._id] = label;
      state.labels.allIds.push(label._id);
    },

    deleteLabel(state: KanbanState, action: PayloadAction<{ labelId: string; cardId?: string }>) {
      const { labelId } = action.payload;
      let cards = state.cards.byId;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cards = _.map(cards, (card) => {
        return _.pull(card.labelIds, labelId);
      });

      state.labels.byId = _.omit(state.labels.byId, labelId);
      _.pull(state.labels.allIds, labelId);
    },

    updateLabel(state: KanbanState, action: PayloadAction<{ label: Label }>) {
      const { label } = action.payload;

      state.labels.byId[label._id] = label;
    },

    assignLabel(state: KanbanState, action: PayloadAction<{ labelId: string; cardId: string }>) {
      const { labelId, cardId } = action.payload;
      const card = state.cards.byId[cardId];

      card.labelIds.push(labelId);
    },

    unassignLabel(state: KanbanState, action: PayloadAction<{ labelId: string; cardId: string }>) {
      const { labelId, cardId } = action.payload;
      const card = state.cards.byId[cardId];

      card.labelIds = _.pull(card.labelIds, labelId);
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;

export const getBoard =
  (kanbanId: string): AppThunk =>
  async (dispatch) => {
    const response = await KanbansService.getKanban(kanbanId);
    let allCollaborators = null;
    if (!response?.projectId) {
      allCollaborators = await CollaboratorsService.getAllCollaborators();
    }

    dispatch(slice.actions.getBoard({ board: response, allCollaborators: allCollaborators }));

    return response;
  };

export const createList =
  (kanbanId: string, name: string): AppThunk =>
  async (dispatch) => {
    const response = await KanbansService.createList(kanbanId, name);

    dispatch(slice.actions.createList(response));

    return response;
  };

export const updateList =
  (listId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateList(kanbanId, listId, update);

    dispatch(slice.actions.updateList(response));

    return response;
  };

export const clearList =
  (listId: string): AppThunk =>
  async (dispatch) => {
    await axios.post('/api/kanban/lists/clear', {
      listId,
    });

    dispatch(slice.actions.clearList({ listId }));
  };

export const deleteList =
  (kanbanId, listId: string): AppThunk =>
  async (dispatch) => {
    await KanbansService.deleteList(kanbanId, listId);

    dispatch(slice.actions.deleteList({ listId }));
  };

export const moveList =
  (previousPosition, position): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    dispatch(
      slice.actions.moveList({
        kanbanId,
        previousPosition,
        position,
      }),
    );
    try {
      await KanbansService.moveList(kanbanId, position, previousPosition);
    } catch (e) {
      dispatch(
        slice.actions.moveList({
          kanbanId,
          previousPosition,
          position,
        }),
      );
    }
  };

export const createCard =
  (kanbanId: string, listId: string, name: string): AppThunk =>
  async (dispatch) => {
    const response = await KanbansService.createCard(kanbanId, listId, name);
    dispatch(slice.actions.createCard(response));
    return response;
  };

export const updateCard =
  (cardId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateCard(kanbanId, cardId, update);
    dispatch(slice.actions.updateCard(response));

    return response;
  };

export const moveCard =
  (
    cardId: string,
    position: number,
    listId?: string,
    previousPosition?: number,
    previousListId?: string,
  ): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    dispatch(
      slice.actions.moveCard({
        cardId,
        position,
        listId,
      }),
    );
    try {
      await KanbansService.moveCard(kanbanId, cardId, position, listId);
    } catch (e) {
      dispatch(
        slice.actions.moveCard({
          cardId,
          position: previousPosition,
          listId: previousListId,
        }),
      );
    }
  };

export const deleteCard =
  (listId, cardId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteCard(kanbanId, listId, cardId);

    dispatch(slice.actions.deleteCard({ cardId }));
  };

export const addComment =
  (cardId: string, message: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.addComment(kanbanId, cardId, message);

    dispatch(slice.actions.addComment(response));

    return response;
  };

export const deleteComment =
  (cardId: string, commentId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteComment(kanbanId, cardId, commentId);

    dispatch(slice.actions.deleteComment({ cardId, commentId }));
  };

export const updateComment =
  (cardId: string, commentId: string, message: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateComment(kanbanId, cardId, commentId, message);

    dispatch(slice.actions.updateComment(response));

    return response;
  };

export const addAttachment =
  (cardId: string, originalFileName: string, file: string, size: number): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const loadingId = uuidv4();

    dispatch(slice.actions.addAttachment({ cardId, loading: true, loadingId }));

    const response = await KanbansService.addAttachment(
      kanbanId,
      cardId,
      originalFileName,
      file,
      size,
    );

    const { attachment } = response;

    dispatch(
      slice.actions.addAttachment({
        attachment,
        cardId,
        loading: false,
        loadingId,
      }),
    );

    return attachment;
  };

export const deleteAttachment =
  (cardId: string, attachmentId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteAttachment(kanbanId, cardId, attachmentId);

    dispatch(
      slice.actions.deleteAttachment({
        cardId,
        attachmentId,
      }),
    );
  };

export const updateAttachment =
  (cardId: string, attachmentId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateAttachment(kanbanId, cardId, attachmentId, update);

    const { attachment } = response;

    dispatch(
      slice.actions.updateAttachment({
        cardId,
        attachment,
      }),
    );

    return attachment;
  };

export const addChecklist =
  (cardId: string, name: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.addChecklist(kanbanId, cardId, name);

    const { checklist } = response;

    dispatch(
      slice.actions.addChecklist({
        cardId,
        checklist,
      }),
    );

    return checklist;
  };

export const updateChecklist =
  (cardId: string, checklistId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateChecklist(kanbanId, cardId, checklistId, update);

    const { checklist } = response;

    dispatch(
      slice.actions.updateChecklist({
        cardId,
        checklist,
      }),
    );

    return checklist;
  };

export const deleteChecklist =
  (cardId: string, checklistId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteChecklist(kanbanId, cardId, checklistId);

    dispatch(
      slice.actions.deleteChecklist({
        cardId,
        checklistId,
      }),
    );
  };

export const addCheckItem =
  (cardId: string, checklistId: string, name: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.addCheckItem(kanbanId, cardId, checklistId, name);

    const { checkItem } = response;

    dispatch(
      slice.actions.addCheckItem({
        cardId,
        checklistId,
        checkItem,
      }),
    );

    return checkItem;
  };

export const updateCheckItem =
  (cardId: string, checklistId: string, checkItemId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateCheckItem(
      kanbanId,
      cardId,
      checklistId,
      checkItemId,
      update,
    );

    const { checkItem } = response;

    dispatch(
      slice.actions.updateCheckItem({
        cardId,
        checklistId,
        checkItem,
      }),
    );

    return checkItem;
  };

export const deleteCheckItem =
  (cardId: string, checklistId: string, checkItemId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteCheckItem(kanbanId, cardId, checklistId, checkItemId);

    dispatch(
      slice.actions.deleteCheckItem({
        cardId,
        checklistId,
        checkItemId,
      }),
    );
  };

export const addCollaborator =
  (cardId: string, collaborator: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.addCollaborator(kanbanId, cardId, collaborator);

    const { collaboratorCard } = response;

    dispatch(
      slice.actions.addCollaborator({
        cardId,
        collaborator: collaboratorCard,
      }),
    );

    return collaboratorCard;
  };

export const deleteCollaborator =
  (cardId: string, collaboratorId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteCollaborator(kanbanId, cardId, collaboratorId);

    dispatch(
      slice.actions.deleteCollaborator({
        cardId,
        collaboratorId,
      }),
    );
  };

export const createLabel =
  (cardId: string, name: string, color: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.createLabel(kanbanId, cardId, name, color);

    dispatch(slice.actions.createLabel({ ...response, cardId }));

    return response;
  };

export const deleteLabel =
  (cardId, labelId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.deleteLabel(kanbanId, cardId, labelId);

    dispatch(slice.actions.deleteLabel({ labelId, cardId }));
  };

export const updateLabel =
  (cardId: string, labelId: string, update: any): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    const response = await KanbansService.updateLabel(kanbanId, cardId, labelId, update);

    dispatch(slice.actions.updateLabel(response));

    return response;
  };

export const assignLabel =
  (cardId: string, labelId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.assignLabel(kanbanId, cardId, labelId);

    dispatch(slice.actions.assignLabel({ cardId, labelId }));
  };

export const unassignLabel =
  (cardId: string, labelId: string): AppThunk =>
  async (dispatch) => {
    const kanbanId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    await KanbansService.unassignLabel(kanbanId, cardId, labelId);

    dispatch(slice.actions.unassignLabel({ cardId, labelId }));
  };

export default slice;
